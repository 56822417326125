<template>
  <th-wrapper
    v-loading="loading"
    :title="$t('common.titles.general_info.title')"
    class="m-8"
  >
    <el-form ref="form" :model="current" :rules="rules">
      <el-row :gutter="10">
        <el-col :md="12">
          <!-- Name -->
          <el-col>
            <el-form-item prop="name" :label="$t('common.forms.labels.name')">
              <el-input
                id="name"
                v-model="current.name"
                :placeholder="$t('common.forms.labels.name')"
              />
            </el-form-item>
          </el-col>
          <!-- Description -->
          <el-col>
            <el-form-item
              prop="description"
              :label="$t('pages.customers.form.placeholder.description')"
            >
              <el-input
                id="description"
                v-model="current.description"
                type="textarea"
                :rows="3"
                :placeholder="$t('pages.customers.form.placeholder.comment')"
              />
            </el-form-item>
          </el-col>
          <!-- Staffs -->
          <el-col>
            <el-form-item
              prop="staffs"
              :label="$t('common.resource.staff.plural')"
            >
              <staffs-item-select
                id="staffs"
                v-model="current.staffs"
                data-testid="staffs-constraint"
                :resources="resources"
              />
            </el-form-item>
          </el-col>
        </el-col>
      </el-row>
    </el-form>
  </th-wrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import staffsGroupModel from '../model/staffs-group-model'
import StaffsItemSelect from '@/containers/multi-items-select/staffs'

function generateDefault() {
  return {
    name: null,
    description: null,
    staffs: []
  }
}

export default {
  name: 'StaffsGroupForm',
  components: { StaffsItemSelect },
  setup() {
    return staffsGroupModel.setup(generateDefault())
  },
  data() {
    return {
      resources: {},
      rules: {
        name: [
          {
            required: true,
            message: this.$t('common.forms.rules.field_warnings.required')
          },
          {
            max: 32,
            message: this.$t('common.forms.rules.max_length', { length: 32 })
          }
        ],
        description: [
          {
            min: 1,
            max: 128,
            message: this.$t('common.forms.rules.max_length', { length: 128 })
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      navigationAfterCreation: 'Config/getNavigationAfterCreation'
    }),
    isNew() {
      return !this.$route.params.id
    }
  },
  async created() {
    this.id = this.$route.params.id
    await this.fetch()
    await this.fetchResources()
  },
  methods: {
    async fetchResources() {
      this.resources = await this.$resourceFetch('staff')
    },
    async submitForm() {
      const formValid = await this.$refs.form.validate()

      if (!formValid) {
        return this.$message({
          type: 'warning',
          message: this.$t(
            'common.forms.rules.field_warnings.invalid_inputs.required'
          )
        })
      }

      const resource = this.$t('common.resource.staff_group.singular')
      const { error } = await this.save()
      //handle errors
      if (error) {
        const errorMessage = this.isNew
          ? this.$t('common.error.action.create.single', { resource })
          : this.$t('common.error.action.update.single', { resource })

        this.$logException(error, {
          message: errorMessage
        })
      }
      //handle success
      if (this.id) {
        const successMessage = this.isNew
          ? this.$t('common.success.action.create.single', { resource })
          : this.$t('common.success.action.update.single', { resource })

        this.$message({
          type: 'success',
          message: successMessage
        })

        if (this.navigationAfterCreation === 'edit') {
          this.$router.push({
            name: 'staff-group-edit',
            params: { id: this.id }
          })
        } else {
          this.$router.push({ name: 'staff-groups-list' })
        }
      }
    },
    async handleDelete() {
      const confirm = await this.$askToDelete(this.current.name || this.id)
      if (confirm) this.deleteStaffsGroups()
    },
    async deleteStaffsGroups() {
      const { error } = await this.deleteModel()
      //handle error
      if (error) {
        return this.$logException(error, {
          message: this.$t('common.error.action.delete.single', {
            resource: this.$t('common.resource.staff_group.singular')
          })
        })
      }

      //handle success
      this.$router.push({ name: 'staff-groups-list' })
    }
  }
}
</script>
