<template>
  <div>
    <select-holder
      :loading="loading"
      :placeholder="$t('components.multi_items_select.select.placeholder')"
      :shown-items="shownItems"
      @show-modal="handleModalShow"
    />

    <th-modal
      name="staffs-select"
      height="800px"
      width="1200px"
      :title="
        $t('pages.settings.users.connect_staff.dialog.select.placeholder')
      "
    >
      <group-items-select
        v-model="selectedStaffs"
        fuzzy-search
        show-search-filter
        resource="staff"
        :filters="filtersList"
        :resource-list="resources.staff"
        :normalise-keys="normaliseKeys"
      />

      <template #footer>
        <actions @cancel="handleCancel" @save="handleSave" />
      </template>
    </th-modal>
  </div>
</template>

<script>
import typeOf from 'just-typeof'
import fullName from '@/utils/full-name'
import GroupItemsSelect from '@/components/group-items-select'
import SelectHolder from './components/select-holder'
import Actions from './components/actions'

export default {
  components: {
    GroupItemsSelect,
    SelectHolder,
    Actions
  },
  props: {
    modelValue: {
      type: Array,
      default: () => []
    },
    resources: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      selectedStaffs: [],
      loading: false,
      filtersList: [
        {
          name: 'staff_number',
          type: 'remote-search-select',
          label: this.$t('pages.staff.all.headers.staff_number'),
          options: [],
          resource: 'staff',
          filterable: true,
          doInitialFetch: false,
          optionsValue: 'staff_number',
          appendToBody: true,
          computeName: (staff) => staff.staff_number,
          modifyQuery: (searchTerm, handlerName) => {
            if (handlerName !== 'search') return { staff_number: searchTerm }
            return {
              q: searchTerm,
              fields: ['staff_number']
            }
          }
        },
        {
          name: 'lastname',
          type: 'remote-search-select',
          label: this.$t('pages.staff.all.headers.lastname'),
          options: [],
          resource: 'staff',
          filterable: true,
          doInitialFetch: false,
          optionsValue: 'lastname',
          appendToBody: true,
          computeName: (staff) => staff.lastname,
          modifyQuery: (searchTerm, handlerName) => {
            if (handlerName !== 'search') return { lastname: searchTerm }
            return {
              q: searchTerm,
              fields: ['lastname', 'firstname']
            }
          }
        },
        {
          name: 'firstname',
          type: 'remote-search-select',
          label: this.$t('pages.staff.all.headers.firstname'),
          options: [],
          resource: 'staff',
          filterable: true,
          doInitialFetch: false,
          optionsValue: 'firstname',
          appendToBody: true,
          computeName: (staff) => staff.firstname,
          modifyQuery: (searchTerm, handlerName) => {
            if (handlerName !== 'search') return { firstname: searchTerm }
            return {
              q: searchTerm,
              fields: ['firstname', 'lastname']
            }
          }
        },
        {
          name: 'email',
          type: 'remote-search-select',
          label: this.$t('pages.staff.all.headers.email'),
          options: [],
          resource: 'staff',
          filterable: true,
          doInitialFetch: false,
          optionsValue: 'email',
          appendToBody: true,
          computeName: (staff) => staff.email,
          modifyQuery: (searchTerm, handlerName) => {
            if (handlerName !== 'search') return { email: searchTerm }
            return {
              q: searchTerm,
              fields: ['email']
            }
          }
        },
        {
          name: 'phonenumbers',
          type: 'remote-search-select',
          label: this.$t('pages.staff.all.headers.phonenumbers'),
          options: [],
          resource: 'staff',
          filterable: true,
          doInitialFetch: false,
          appendToBody: true,
          computeName: (staff, q) => this.parsePhoneNumbers(staff, q),
          expandOriginalData: (staff, q) => this.parsePhoneNumbers(staff, q),
          modifyQuery: (searchTerm, handlerName) => {
            if (handlerName !== 'search') return { phonenumbers: searchTerm }
            return {
              q: searchTerm,
              fields: ['phonenumbers']
            }
          }
        }
      ]
    }
  },
  computed: {
    shownItems() {
      return this.resources.staff
        ?.filter((staff) => this.modelValue.includes(staff.id))
        .map((staff) => ({ id: staff.id, name: fullName(staff) }))
    }
  },
  methods: {
    normaliseKeys(staff) {
      return {
        ...staff,
        title_main: this.$formatStaff(
          staff,
          ['staff_number', 'fullName'],
          ' - '
        )
      }
    },
    parsePhoneNumbers(staff, q) {
      if (typeOf(staff.phonenumbers) === 'string') return staff.phonenumbers
      if (!q) return

      if (typeOf(staff.phonenumbers) === 'object') {
        return Object.values(staff.phonenumbers).find((n) => n.includes(q))
      }
    },
    handleCancel() {
      this.$thModal.hide('staffs-select')
    },
    handleSave() {
      this.$emit('update:modelValue', this.selectedStaffs)
      this.$thModal.hide('staffs-select')
    },
    handleModalShow() {
      this.selectedStaffs = [...this.modelValue]
      this.$thModal.show('staffs-select')
    }
  }
}
</script>
