<template>
  <th-page-wrapper
    :actions="actions"
    @delete="handleDelete"
    @save="handleSubmit"
  >
    <resource-form ref="form" />
  </th-page-wrapper>
</template>

<script>
import ResourceForm from './components/form.vue'

export default {
  name: 'StaffGroupsEdit',
  components: {
    ResourceForm
  },
  computed: {
    isNew() {
      return !this.$route.params.id
    },
    actions() {
      return {
        permissionPrefix: 'resources:staff_groups',
        returnedPath: { name: 'staff-groups-list' },
        isNew: this.isNew
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$refs.form.submitForm()
    },
    handleDelete() {
      this.$refs.form.handleDelete()
    }
  }
}
</script>
